:root{
    --backGrondColor: rgb(0,0,0);
    --textColor: rgb(255, 255, 255);
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Times New Roman', Times, serif;
}
.form-container{
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}
.form-container > .form-entry{
    width: 100%;
    padding: 10px 20px;
    border-radius:5px;
    height: auto;
    padding-top: 30px;
    
}

.form-container > .form-sub-container .d-flex-contanier .password-testing input[type="password"], input[type="text"], input[type="email"],input[type="date"], select, input[type="number"]{
    display: block;
    width: 100%;
    border-radius: 50px;
    padding: 10px 20px;
    color:darkblue !important;
}
select{
    display: block !important;
    width: 100% !important;
    border-radius: 50px !important;
    padding: 10px 20px !important; 
  
}
select, textarea{
    margin-bottom: 20px !important;
}
input:focus, select:focus, textarea{
    box-shadow: none !important;
}
.country-code, .input-margin{
    display: grid;
    grid-template-columns: 40% auto;
    
}
.input-margin-1{
    display: grid;
    grid-template-columns: 40% auto;
}
.input-margin-1 .first-child{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.input-margin-1 .last-child{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.input-margin .first-child{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.input-margin .last-child{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.country-code select{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.country-code input[type="number"]{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    height: 45.81px;
}
.info-container{
    position: relative;
}
.icon-info{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.card-info{
    width: 100%;
    margin-bottom: 13px;
    /* padding: 0px 50px; */
    
}
.card-heading-span{
    font-weight: bold;
}
.header-info{
    display: flex;
    justify-content: space-between;
}
.header-info a{
    display: block;
    font-size: 20px;
    color: red;
}


.title-info{
    font-weight: bold;
    font-size: 35px;
    border-bottom: 1px solid;
    padding-left: 48px;
    margin-bottom: 15px;
}
.title-info span{
    font-size: 14px;
}
.card-info-footer a{
    display: block;
    text-align: center;
    text-decoration: none;
    background-color: darkblue;
    padding: 10px;
    color: #fff !important;
    font-weight: bold;
    border-radius: 20px;
    
}
.divider-info{
    border: none;
    background-color: darkblue;
    height: 2px;
    border-radius: 50%;
}
/* //===============================================filter booking */

.first-child{
    height: 46px;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
}
.delete-container{

    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 30px;
}
.delete-sub-container{
    width: 300px;
}
.delete-sub-container ul{
    margin-top: 40px;
}

.delete-info{
    color: red;
    text-align: left;
    width: 300px;
}
.delete-flex{
    display: flex;
    justify-content: space-between;
}
.delete-flex span{
    display: block;
    width: 50%;
}
.advance-style{
    display: grid;
    grid-template-columns: 60% auto;
}
.advance-style .advance-child-first{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.advance-style .advance-child-last{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
@media only screen and (max-width: 450px){    
    .card-info{
        padding: 0px 8px !important;
    }
    .title-info{
        padding-left: 8px;
    }
    .advance-style{
        grid-template-columns: 40% auto;
    }
}
@media only screen and (max-width: 360px) {
    .form-container > .form-entry{
        padding: 10px 0px;
    }
    .input-margin-1{
        grid-template-columns: 30% auto;
    }
}
.alert-style{
    position: relative;
}
.info-alert-total-delivery{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 5px;
   
}
.total-alert-delivery-info{
    display: grid;
    grid-template-columns: 50% 50%;
   
   
}
.divider-delivery{
    border: none;
    background-color: darkblue;
    height: 1px;
    border-radius: 50%;
}
.total-alert-delivery-info li span{
    display: inline-block;
    width: 100px;
    text-align: right;
}
.delivery-status{
    padding-left: 30px !important;
    padding-bottom: 20px;
}
.delivery-status span{
    display: inline-block;
    width: 221px;
}
.bi-check-circle-fill {
    margin-left: 35%;
    margin-right: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: green;
    font-size: 50px;
}
.update-add-delivery{
    color: rgb(101, 101, 161);
    cursor: pointer;
}
