:root{
    --backGrondColor: rgb(0,0,0);
    --textColor: rgb(255, 255, 255);
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Times New Roman', Times, serif;
}
.form-container{
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}
.form-container > .form-entry{
    width: 100%;
    padding: 10px 20px;
    border-radius:5px;
    height: auto;
    padding-top: 30px;
    
}

.form-container > .form-sub-container .d-flex-contanier .password-testing input[type="password"], input[type="text"], input[type="email"],input[type="date"], select, input[type="number"]{
    display: block;
    width: 100%;
    border-radius: 50px;
    padding: 10px 20px;
    color:darkblue !important;
}
select{
    display: block !important;
    width: 100% !important;
    border-radius: 50px !important;
    padding: 10px 20px !important; 
  
}
select, textarea{
    margin-bottom: 20px !important;
}
input:focus, select:focus, textarea{
    box-shadow: none !important;
}
.country-code, .input-margin{
    display: grid;
    grid-template-columns: 40% auto;
}
.input-margin-1{
    display: grid;
    grid-template-columns: 40% auto;
}
.input-margin-1 .first-child{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.input-margin-1 .last-child{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.input-margin .first-child{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.input-margin .last-child{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.country-code select{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.country-code input[type="number"]{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    height: 45.81px;

}
@media only screen and (max-width: 360px) {
    .form-container > .form-entry{
        padding: 10px 0px;
    }
    .input-margin-1{
        grid-template-columns: 30% auto;
    }
}



/* //======================================search style */
.total-booking-delivery{
    display: flex;
    flex-flow: column;
    height: auto;
    max-width: 80%;
    margin: 0px auto;
    padding: 20px 20px 3px 20px;
    border-bottom: 1px solid;
}
.total-booking-deliveries, .total-records{
    display: flex;
    flex-flow: column;
    height: auto;
    max-width: 80%;
    margin: 0px auto;
    padding: 20px 20px 3px 20px;
}
.total-bookingcontainer, .total-delivery-container, .total-footer-amount{
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 20px 20px;
    grid-auto-rows: 20px;
    padding: 10px 20px;
    
}
.total-amount{
    display: grid;
    grid-template-columns: 55% auto;
    grid-template-rows: 20px 20px;
    grid-auto-rows: 20px;
    padding: 10px 20px;
}

.total-delivery-container{
    border-bottom: 2px solid darkblue;
}

.delivery-info-details{
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    padding: 20px;
}
.delivery-info-details::first-letter{
    letter-spacing: 3px;
    font-size: 30px;
    color: darkblue;
    text-shadow: 2px 1px 5px rgba(0,0,0,0.3);
}
.alert-value{
    background-color: red;
    color: #fff;
    padding-left: 5px;
}
.alert-success{
    background-color: green;
    color: #fff;
    padding-left: 5px;
}
.status-font{
    display: inline-block;
    color: darkblue;
    text-shadow: 5px 5px 5px rgba(78, 48, 48, 0.3);
}
hr{
    border: none;
    width: 100%;
    height: 2px;
    background-color: darkblue;
}
.modal-footer{
    justify-content: space-around;
}
.modal-footer button{
    background: linear-gradient(white, darkblue, gray);
    border: none;
}
.modal-footer .bi-box-arrow-down, .bi-x-octagon-fill{
    font-weight: bold;
    font-size: 20px;
}
.close-button{
    width: 27px;
    height: 27px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 2px 1px 3px 2px darkblue inset;
}

@media only screen and (max-width: 992px){
    .total-booking-delivery, .total-booking-deliveries{
        width: 100%;
        padding: 0px;
    }   
    .total-bookingcontainer, .total-delivery-container, .total-amount, .total-footer-amount{
        padding: 10px;
    }
    .modal-content{
        margin-left: 2px !important;
        padding-right: 2px !important;
    }
}
.modal-titles{
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    min-width: 100%;
    align-items: center;   
}

.icon-close-xy{
    height: 24px !important;
    color: rgb(200, 139, 139)
}
.export-to-excel{
    display: block;
    background-color: rgb(131, 197, 131);
    border-radius: 10px;
    outline: none;
    border: 0.1px solid green;
    padding: 0px 10px;
    margin-left: auto;
    margin-right:auto ;
}

.export-heading{
    background-color: darkblue;
    color: #fff;
    font-weight: 600;
}