*{
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  padding: 0px;
  margin: 0px;
}
ul{
  padding: 0px;
  margin: 0px;
}
body{
  padding: 0px;
  margin: 0px;
}

.content-area{
  margin-left: 380px;
 
}
.content-sub-area{
  margin-top: 50px;
}
@media only screen and (max-width: 767px) { 
  .content-area{
    margin-left: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* //====================================header styling */

.header{
  background-color: #fff;
  position: relative;
  position: sticky;
  top: 0px;
}
.shop-title{
line-height: 55px;
font-weight: bold;
font-size: 25px;
font-family: 'Times New Roman', Times, serif;
}

.shop-title::first-letter{
color: darkblue;
}
.header-style-nav{
  align-items: center;
  padding-top: 20px;
  padding-bottom:20px ;
 
}

.header-nav-logo{
  width: 60px;
  height: 60px;
  max-width: 100%;
  border-radius: 50%;
}
.header-col-2{
  text-align: right;
}

.form-control:focus {
  border-color: none !important;
   box-shadow: 0 0 0 0 !important;
}
.header-search-input{
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-radius: 50px;
  font-family: 'Times New Roman', Times, serif;
  padding-left: 20px;
}
.header-search-btn{
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  height: 37.8px !important;
  border-radius: 50px;
  background-color: darkblue;
}
@media only screen and (max-width: 950px) { 
  .shop-title{
    font-size: 17px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 767px) { 
 
  .shop-title::first-letter{
    color: darkblue;
    font-size: 35px;
    letter-spacing: 2px;
  }
}
@media only screen and (max-width: 490px) {
  .shop-title{
    font-size: 20px;
    top: 38%;
  }
  .shop-title::first-letter{
    font-size: 22px;
  }
}
@media only screen and (max-width: 331px) {
  .shop-title{
    font-size: 16px;
  }
}