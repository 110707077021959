:root{
    --backGrondColor: rgb(0,0,0);
    --textColor: rgb(255, 255, 255);
}

.auth-form-container{
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.auth-form-container > .auth-form-sub-container{
    width: 320px;
    padding: 10px 20px;
    box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.5) inset;
    border-radius:5px;
    height: auto;
    padding-top: 100px;
    
}
.auth-margin-container{
    padding: 0px 0px 100px 0px !important;
}
.auth-form-container > .auth-form-sub-container .auth-signup-title{
    text-align: center;
}
.auth-form-container > .auth-form-sub-container .signup-title::first-letter{
    color: darkblue;
    font-weight: bold;
    font-size: 40px;
    text-shadow: 0.5px 0px 2px darkblue;
}

@keyframes moving {
    0%{
        transform: rotateX(-30deg);
    }
    100%{
        transform: rotate(30deg);
    }
}

.auth-form-container > .auth-form-sub-container .auth-forget-contanier{
    display: flex;
    flex-flow: column;
    margin-bottom: 40px;
    
}
.auth-form-container > .auth-form-sub-container .auth-forget-contanier h3{
    text-align: center;
    font-weight: 1000;
    line-height: 35px;
}
.auth-form-container > .auth-form-sub-container .auth-forget-contanier p{
    font-size: 12px;
    text-align-last: center;
    color: rgb(87, 85, 82);
}
.auth-form-container > .auth-form-sub-container .d-flex-contanier{
    display: flex;
    flex-flow: column;
    margin-top: 15px;
}
.auth-form-container > .auth-form-sub-container .d-flex-contanier > label{
    margin-bottom: 5px;
   
}
.auth-form-container > .auth-form-sub-container .d-flex-contanier .password-testing{
    position: relative;
}
.auth-form-container > .auth-form-sub-container .d-flex-contanier .password-testing input[type="password"], input[type="text"], input[type="email"],input[type="date"], select, input[type="number"]{
    display: block;
    width: 100%;
    border-radius: 15px;
    padding: 10px 20px;
    color:darkblue !important;
}
select{
    display: block !important;
    width: 100% !important;
    border-radius: 15px !important;
    padding: 10px 20px !important; 
    margin-bottom: 5px !important;
}
input:focus, select:focus, textarea{
    box-shadow: none !important;
}
.auth-form-container > .auth-form-sub-container .d-flex-contanier .password-testing .bi-eye-slash-fill, .bi-eye-fill{
position: absolute;
right: 0%;
top: 12px;
padding-right:10px ;
font-size: 20px;
cursor: pointer;
}
.auth-form-container > .auth-form-sub-container .form-btn{
    display: block;
    width: 100%;
    margin-bottom: 8px;
    margin-top: 8px;
    padding: 10px 0px;
    border-radius: 15px;
    background-image: linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB);
    border: none;
    font-weight: 500;
    font-size: 18px;
    color: var(--textColor);
    cursor: pointer;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
}
.auth-form-container > .auth-form-sub-container .posintion-relative{
    position: relative;
    margin: 46.6px 0px;
}
.auth-form-container > .auth-form-sub-container .posintion-relative span{
    outline: none;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid;
    padding: 2px 5px;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: -12px;
    background-color: rgb(255, 255, 255);
    transform: translateX(-50%);
    color: darkblue;
    font-weight: bold;
}
.auth-form-container > .auth-form-sub-container .link-connect{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}
.auth-form-container > .auth-form-sub-container .link-connect a{
    display: inline-block;
    color: darkblue;
    font-weight: bold;
    border-bottom: 0px solid;
    text-decoration: none;
    transition: all 3s ease;
    cursor: pointer;
    text-decoration: none;
}
.error-message{
    color: red;
    font-weight: 500;
}
.forget-tital{
    margin-bottom: 4px;
    display: flex;
    justify-content: flex-end;
}
.forget-tital a{
    display: inline-block;
    font-weight: bold;
    text-decoration: none;
    color: darkblue;
}
.bi-chevron-double-left{
    font-size: 12px;
}
/*===============================update messgae style =============*/

.forget-contanier .button-style{
    display: inline;
    width: 50px;
}
@media only screen and (max-width: 370px) {
    .form-container > .form-sub-container{
        width: 100%;
        border-radius: 0px;
        box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.5) inset;
    }
    .form-container > .form-sub-container .form-btn{
        cursor: default;
    }
}