
.info-heading{
    margin-top: 50px;
    margin-left: 14px;
}
.info-heading h1{
    font-weight: bold;
}
.info-heading h1::first-letter{
    letter-spacing: 5px;
    color: darkblue;
}
.card-container{
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    z-index: -1 !important;
}
.card{
    width: 300px;
    margin-right:8px;
    margin-bottom: 13px;
    background-color: white !important;
    border: none;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    
}
.card:nth-child(even){
    background-color: darkblue !important;
}

.card-info{
    width: 100%;
    margin-bottom: 13px;
    padding: 0px 50px;
}
.card-header{
    background-color: #fff;
    padding-top:20px;
    padding-bottom:20px ;
}
.card-body{
    height: auto;
    background-color: #fff !important;
}
.card-body li{
    display: flex;
    flex-grow: 1;
}
.card-body li p{
    flex-basis: 50%;
}
.card-footer{
    background-color: darkblue;
}
.card-footer a{
    display: block;
    text-align: center;
    text-decoration: none;
    background-color: darkblue;
    padding: 10px;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
}
.total-info{
    margin-top: 30px;
    width: 300px;
}
.info-total{
    display: flex;
    justify-content: space-around;
}
@media only screen and (max-width: 767px) {
    .info-heading h1{
        text-align: center;
    }
    .info-heading{
        margin-top: 0px;
        margin-left: 0px;
    }
    .card{
        width: 300px
    }
}
.update-link{
    color: rgb(15, 92, 200);
    cursor: pointer;
}