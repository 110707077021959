.title-icon{
    display: flex;
    justify-content: space-between;
    height: 100px;
    align-items: center;
    padding-left: 50px;
    padding-right: 10px;
    box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.1);
  
   
}
.logo-fixing{
    position: fixed;
    left: 100px;
    z-index: 1;
    top: 50px

}
.menu-icon{
    font-size: 35px;
    font-weight: bold;
    cursor: pointer;
    color: darkblue;
    transition: all 0.5s ease-in;
  }

  .menu-icon.inactive{
    transform: rotateX(180deg);
  }

    .side-bar{
        position: fixed;
        height: 100%;
        width: 350px;
        background-color: #fff;
        overflow-y: auto;
        padding-left: 50px; 
        padding-right: 20px;
        box-shadow: 1px 0px 0.2px 0.1px rgba(0,0,0,0.1);
    
    }
 
    .side-bar .sidebar-content{
        margin-top: 135px;
    }




.nav-items{
    font-weight: bold;
    font-size: 17px;
    padding-left: 10px;
    
}

.nav-items-child{
    display: flex;
}
.nav-items-child .nav-icon{
    display: inline-block;
    padding-right: 10px;
    position: relative;
}
.nav-drop-down{
    padding: 0px 10px ;
    cursor: pointer;
}
.nav-items a{
    display: block;
    text-decoration: none;
    font-family: 'Times New Roman', Times, serif;
    color: black;
}
.nav-bar-sub-list{
    padding-left: 20px;
    font-size: 16px;
}
.details{
    padding-left: 35px;
}
.details-info{
    width: 100px;
    font-size: 14px;
}
.details-sublist-info{
    text-align: right;
    display: inline-block;
    font-size: 14px;
    font-family: 'Times New Roman', Times, serif;
   
}
.side-bar .bi-escape{
    transform: rotate(43deg);
    margin-top: 5px;
}
@media only screen and (max-width: 767px) { 
    
    .side-bar{
        position: fixed;
        background-image: linear-gradient( rgb(103, 105, 103, 0.1), rgb(2, 0, 0, 0.3)); 
        opacity: 1;
        height: 100%;
        width: 75%;
        overflow: auto;
        top: 0;
        left: 0;
        z-index: 1;
        transition: all 0.5s ease-in;
    }
    .side-bar.sidebar{
        padding: 0px;
        width: 0%;
        overflow: hidden;
        transition: all 0.5s ease-in;
    }
    .side-bar.open{
        width: 0%;
        overflow: hidden;

    }
    .side-bar-search{
        margin-top: 150px;
    }
    .side-bar-items{
        padding: 23px 25px;
    }
    }
    @media only screen and (max-width: 425px) {
        .side-bar{
            padding-left: 10px;
        }
        .side-bar-items{
            padding: 23px 8px;
        }
    }