.outside-loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: auto;
  }
  .outside-loader div {
    position: absolute;
    top: -10px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .outside-loader div:nth-child(1) {
    left: 8px;
    animation: outside-loader1 0.6s infinite linear;
  }
  .outside-loader div:nth-child(2) {
    left: 8px;
    animation: outside-loader2 0.6s infinite linear;
  }
  .outside-loader div:nth-child(3) {
    left: 32px;
    animation: outside-loader2 0.6s infinite linear;
  }
  .outside-loader div:nth-child(4) {
    left: 56px;
    animation: outside-loader3 0.6s infinite linear;
  }
  @keyframes outside-loader1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes outside-loader3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes outside-loader2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }


  /* //=============================home loader  */
  .center {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
  }
  .wave {
    width: 5px;
    height: 100px;
    background: linear-gradient(45deg, cyan, #fff);
    margin: 10px;
    animation: wave 1s linear infinite;
    border-radius: 20px;
  }
  .wave:nth-child(2) {
    animation-delay: 0.1s;
  }
  .wave:nth-child(3) {
    animation-delay: 0.2s;
  }
  .wave:nth-child(4) {
    animation-delay: 0.3s;
  }
  .wave:nth-child(5) {
    animation-delay: 0.4s;
  }
  .wave:nth-child(6) {
    animation-delay: 0.5s;
  }
  .wave:nth-child(7) {
    animation-delay: 0.6s;
  }
  .wave:nth-child(8) {
    animation-delay: 0.7s;
  }
  .wave:nth-child(9) {
    animation-delay: 0.8s;
  }
  .wave:nth-child(10) {
    animation-delay: 0.9s;
  }
  
  @keyframes wave {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  
  
  

  